import { Stack } from "@chakra-ui/layout";
import { observer } from "mobx-react-lite";

export const NotificationSettings = observer(function NotificationSettings() {
  // const { editUserModalStore } = useStore();

  return (
    <Stack
      align={{
        base: "center",
        md: "start",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
      overflowY="auto"
      spacing="4"
    ></Stack>
  );
});
