import { Box, Stack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { AvatarUpload, FormRow, TextField } from "@src/components/ui-kit";
import { can, cannot } from "@src/utils/components/permissions";
import { fieldToInputProps } from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import { BrandSelect } from "@src/widgets/BrandSelect/BrandSelect";
import { PositionSelect } from "@src/widgets/PositionSelect";
import { RoleSelect } from "@src/widgets/RoleSelect/RoleSelect";
import { TaskOption, TaskSelect } from "@src/widgets/TaskSelect/TaskSelect";
import { TeamSelect } from "@src/widgets/TeamSelect";
import { observer } from "mobx-react-lite";

const USER_PLACEHOLDER_IMAGE =
  process.env.NODE_ENV === "test" ? "" : require("@src/assets/img/user.png");

export const PersonalSettings = observer(function PersonalSettings() {
  const { editUserModalStore, workspaceStore } = useStore();

  const hasPermissionsPersonalData =
    can("user_updatePersonalData_all") ||
    can({
      do: "user_updatePersonalData_own",
      eval: ({ id }) => id === editUserModalStore.user?.id,
    });

  return (
    <Stack
      align={{
        base: "center",
        md: "start",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
      overflowY="auto"
      spacing="4"
    >
      <Box w="25%" mt="4">
        <AvatarUpload
          placeholderImage={USER_PLACEHOLDER_IMAGE}
          roundedFull
          imageSrc={
            typeof editUserModalStore.form.avatar.value === "string"
              ? editUserModalStore.form.avatar.value
              : ""
          }
          onChange={(val) => {
            editUserModalStore.form.avatar.onChange(val);
          }}
        />
      </Box>

      <Box
        w={{
          base: "full",
          md: "75%",
        }}
      >
        <FormRow title={<Trans>First name</Trans>}>
          <TextField
            isDisabled={!hasPermissionsPersonalData}
            id="field-user-first-name"
            {...fieldToInputProps(editUserModalStore.form.first_name)}
            type="text"
          />
        </FormRow>
        <FormRow title={<Trans>Last name</Trans>}>
          <TextField
            isDisabled={!hasPermissionsPersonalData}
            id="field-user-last-name"
            {...fieldToInputProps(editUserModalStore.form.last_name)}
            type="text"
          />
        </FormRow>
        <FormRow title={<Trans>Codename</Trans>}>
          <TextField
            isDisabled={!hasPermissionsPersonalData}
            id="field-user-codename"
            {...fieldToInputProps(editUserModalStore.form.codename)}
            type="text"
          />
        </FormRow>
        <FormRow title={<Trans>E-mail</Trans>}>
          <TextField
            isDisabled={!hasPermissionsPersonalData}
            id="field-user-email"
            placeholder={t`example@web.sk`}
            {...fieldToInputProps(editUserModalStore.form.email)}
            type="email"
          />
        </FormRow>
        {editUserModalStore.form.hasAccessToField("phone") && (
          <FormRow title={<Trans>Phone</Trans>}>
            <TextField
              isDisabled={!hasPermissionsPersonalData}
              id="field-user-phone"
              placeholder={t`+421 111 222 333`}
              {...fieldToInputProps(editUserModalStore.form.phone)}
              type="tel"
            />
          </FormRow>
        )}
        {editUserModalStore.form.hasAccessToField("brand_ids") &&
          can("user_updateAllfredData") && (
            <FormRow title={<Trans>Access to brands</Trans>}>
              <BrandSelect
                asPortal
                isMulti
                id="field-user-brands"
                {...fieldToInputProps(editUserModalStore.form.brand_ids)}
              />
            </FormRow>
          )}
        {editUserModalStore.form.hasAccessToField("task_ids") &&
          can("user_updateAllfredData") && (
            <FormRow title={<Trans>Access to tasks</Trans>}>
              <TaskSelect
                asPortal
                isMulti
                withCreateTask={false}
                value={editUserModalStore.form.task_ids.value}
                onChange={(taskId, options) => {
                  editUserModalStore.form.task_ids.onChange(taskId as string[]);
                  editUserModalStore.form.selectedTasks = [
                    ...(editUserModalStore.form.selectedTasks ?? []),
                    ...(options as TaskOption[]),
                  ];
                }}
                options={[]}
                defaultOptions={editUserModalStore.form.selectedTasks}
                id="field-user-tasks"
              />
            </FormRow>
          )}
        {editUserModalStore.form.hasAccessToField("note") && (
          <FormRow title={<Trans>Note</Trans>}>
            <TextField
              isDisabled={!hasPermissionsPersonalData}
              id="field-user-note"
              {...fieldToInputProps(editUserModalStore.form.note)}
              type="text"
            />
          </FormRow>
        )}
        {editUserModalStore.form.hasAccessToField("team_id") && (
          <FormRow title={<Trans>Team</Trans>}>
            <TeamSelect
              isDisabled={cannot("user_updateWorkData")}
              asPortal
              {...fieldToInputProps(editUserModalStore.form.team_id)}
            />
          </FormRow>
        )}
        {editUserModalStore.form.hasAccessToField("default_work_type_id") && (
          <FormRow title={<Trans>Position</Trans>}>
            <PositionSelect
              isDisabled={!hasPermissionsPersonalData}
              asPortal
              id="field-user-position"
              {...fieldToInputProps(
                editUserModalStore.form.default_work_type_id,
              )}
            />
          </FormRow>
        )}
        {editUserModalStore.form.hasAccessToField("role_ids") &&
          can("user_updateAllfredData") && (
            <FormRow title={<Trans>Role</Trans>}>
              <RoleSelect
                asPortal
                isMulti
                placeholder={t`Select roles`}
                {...fieldToInputProps(editUserModalStore.form.role_ids)}
              />
            </FormRow>
          )}
        {editUserModalStore.form.hasAccessToField("password") && (
          <FormRow title={<Trans>Password</Trans>}>
            <TextField
              id="field-user-password"
              {...fieldToInputProps(editUserModalStore.form.password)}
              type="password"
            />
          </FormRow>
        )}
        <FormRow>
          {editUserModalStore.user?.enabled_two_factor_authentication ? (
            <Button
              justifyContent="start"
              w="full"
              fontSize="sm"
              isDisabled={cannot("user_password_update")}
              onClick={() => editUserModalStore.disable2FA()}
              variant="link"
            >
              {workspaceStore.settings?.require_two_factor_authentication ? (
                <Trans>Reset</Trans>
              ) : (
                <Trans>Disable</Trans>
              )}{" "}
              <Trans>Two-Factor Authentication</Trans>
            </Button>
          ) : (
            <Button
              justifyContent="start"
              w="full"
              fontSize="sm"
              isDisabled={cannot("user_password_update")}
              onClick={() => editUserModalStore.enable2FA()}
              variant="link"
            >
              <Trans>Enable Two-Factor Authentication</Trans>
            </Button>
          )}
        </FormRow>
      </Box>
    </Stack>
  );
});
