import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

export const UserInvitationSentModal = observer(
  function UserInvitationSentModal() {
    const { userInvitationSentModalStore } = useStore();

    const texts = {
      client: {
        title: t`Client access invitation`,
        content: t`Client content TODO: texts...`,
      },
      user: {
        title: t`User`,
        content: t`User content`,
      },
      // TODO: texts...
    };

    return (
      <Modal
        isCentered
        isOpen={userInvitationSentModalStore.modalState.isOpen}
        onClose={userInvitationSentModalStore.modalState.onClose}
        size="lg"
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton zIndex="2" bg="white" />

          <ModalHeader>{texts.client.title}</ModalHeader>
          <ModalBody>
            <VStack textAlign="center" spacing="3">
              <Icon w="16" h="16" color="green.500" name="check-circle" />
              <Heading color="green.500" fontWeight="semibold">
                <Trans>Invitation Sent</Trans>
              </Heading>
              <Text>{texts.client.content}</Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="violet"
              onClick={userInvitationSentModalStore.modalState.onClose}
            >
              <Trans>Done</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);
