import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { t } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import {
  DisableTwoFactorAuthenticationDocument,
  DisableTwoFactorAuthenticationMutation,
  DisableTwoFactorAuthenticationMutationVariables,
  GetUserForEditDocument,
  GetUserForEditQuery,
  GetUserForEditQueryVariables,
  SetUserProfilePlanningRangesDocument,
  SetUserProfilePlanningRangesMutation,
  SetUserProfilePlanningRangesMutationVariables,
  SetUserProfileRatesDocument,
  SetUserProfileRatesMutation,
  SetUserProfileRatesMutationVariables,
  UpdateScopedUserDocument,
  UpdateScopedUserMutation,
  UpdateScopedUserMutationVariables,
  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "@src/__generated__/graphql";
import { EditUserModal } from "@src/components/modules/users/listing/form/EditUserModal";
import { FormUser } from "@src/components/modules/users/listing/form/FormUser";
import { client } from "@src/services/apollo-client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { UserType } from "@src/stores/models/Me";
import { nominate } from "@src/utils/formatters";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { WeekDays } from "@src/utils/types";
import { map, omit } from "lodash";
import { action, makeObservable, observable } from "mobx";
import Router from "next/router";

export type TCategory =
  | "personal"
  | "notifications"
  | "planning-utilization"
  | "hourly-rates";
type TUser = NonNullable<GetUserForEditQuery["user"]>;
type EditUserModalOptions = {
  userId: string;
  userType: UserType;
  onSubmit?: () => void;
};

export class EditUserModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "userEditModal";
  @observable category: TCategory = "personal";
  @observable.ref form: FormUser;
  @observable.ref user?: TUser;
  @observable isLoading = false;
  @observable isSubmitting = false;
  @observable modalState = new DisclosureState<EditUserModalOptions>({
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
      this.form.reset();
      this.setCategory("personal");
      this.user = undefined;
    },
    onOpen: (additionalData) => {
      this.form.userType = additionalData?.userType!;
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <EditUserModal />,
      });
      this.fetchUser();
    },
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.form = new FormUser({
      formVariant: "edit",
      appStore,
    });
  }

  async fetchUser() {
    this.isLoading = true;
    try {
      const { data } = await client.query<
        GetUserForEditQuery,
        GetUserForEditQueryVariables
      >({
        query: GetUserForEditDocument,
        variables: { id: this.modalState.additionalData!.userId },
      });

      if (!data?.user) {
        throw new Error("No data returned from the server");
      }

      this.user = data.user;
      this.fillUserForm(data.user);
    } catch (error) {
      this.modalState.onClose();
      this.appStore.UIStore.toast({
        title: t`Unable to load user. Please try again later.`,
        status: "error",
      });
      captureEvent({
        message: "Unable to load user",
        extra: { error },
      });
    }
    this.isLoading = false;
  }

  fillUserForm(user: TUser) {
    this.form.first_name.onChange(user.first_name);
    this.form.last_name.onChange(user.last_name);
    this.form.codename.onChange(user.codename || "");
    this.form.email.onChange(user.email);
    this.form.phone.onChange(user.phone || "");
    this.form.note.onChange(user.note || "");
    this.form.team_id.onChange(user.team?.id || "");
    this.form.role_ids.onChange(map(user.roles, (r) => r.id));
    this.form.avatar.onChange(user.image?.urls.thumbnail);
    this.form.brand_ids.onChange(user.brands.map((b) => b.id));
    this.form.task_ids.onChange(user.tasks.map((t) => t.id));
    this.form.selectedTasks = this.user?.tasks.map((t) => ({
      value: t.id,
      label: t.name,
      projectId: t.ourWorkBudgetItem?.project.id,
      budgetItemId: t.ourWorkBudgetItem?.id,
      billable: t.billable,
      timeTrackingSettings: undefined,
    }));
    if (user.profile) {
      this.form.default_work_type_id.onChange(user.profile.defaultWorktype.id);
      user.profile.rates.map((rate) => {
        this.form.addHourlyRate(undefined, "add", {
          id: rate.id,
          valid_from: new Date(rate.valid_from),
          valid_to: rate.valid_to ? new Date(rate.valid_to) : undefined,
          rate: nominate(
            rate.rate,
            this.appStore.workspaceStore.settings?.currency.denomination!,
          ),
        });
      });
      user.profile.planningRanges.map((range) => {
        this.form.addPlanningRange(undefined, "add", {
          id: range.id,
          type: range.type.value,
          valid_from: new Date(range.valid_from),
          valid_to: range.valid_to ? new Date(range.valid_to) : undefined,
          utilising: range.utilising,
          active: range.active,
          weekly_capacities: !!range.weekly_capacities
            ? (omit(range.weekly_capacities, "__typename") as WeekDays<number>)
            : undefined,
          daily_capacity: range.daily_capacity ?? undefined,
        });
      });
      this.form.plannable.onChange(user.profile.plannable);
      this.form.hex_color.onChange(user.profile.hex_color);
    }
  }

  @action.bound
  setCategory(category: TCategory) {
    this.category = category;
  }

  async onSubmit() {
    this.isSubmitting = true;
    switch (this.category) {
      case "personal":
        await this.onSubmitPersonalSettings();
        break;
      case "hourly-rates":
        await this.onSubmitHourlyRates();
        break;
      case "planning-utilization":
        await this.onSubmitPlanningAndUtilization();
        break;
      case "notifications":
        // TODO: implement notifications
        break;
    }
    this.isSubmitting = false;
    this.modalState.additionalData?.onSubmit?.();
  }

  private async onSubmitPersonalSettings() {
    const userType = this.modalState.additionalData?.userType!;
    const formResponse = await this.form.personalSettingsForm.validate();
    if (formResponse.hasError) return;

    const mutation = {
      internal: UpdateUserDocument as TypedDocumentNode<
        UpdateUserMutation,
        UpdateUserMutationVariables
      >,
      client: UpdateScopedUserDocument as TypedDocumentNode<
        UpdateScopedUserMutation,
        UpdateScopedUserMutationVariables
      >,
      partner: UpdateScopedUserDocument as TypedDocumentNode<
        UpdateScopedUserMutation,
        UpdateScopedUserMutationVariables
      >,
    }[userType];

    try {
      const response = await client.mutate({
        mutation,
        variables: {
          // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
          // @ts-ignore
          input: {
            id: this.modalState.additionalData!.userId,
            ...this.form.serializePersonalSettings(userType),
          },
        },
      });

      if (!response.errors?.length) {
        this.appStore.UIStore.toast({
          title: t`User was updated successfully`,
          status: "success",
        });
        this.modalState.onClose();
      }
    } catch {
      this.appStore.UIStore.toast({
        title: t`Unable to update user. Please try again later.`,
        status: "error",
      });
    }
  }

  private async onSubmitHourlyRates() {
    const formResponse = await this.form.hourlyRates.validate();
    if (formResponse.hasError) return;

    try {
      const response = await client.mutate<
        SetUserProfileRatesMutation,
        SetUserProfileRatesMutationVariables
      >({
        mutation: SetUserProfileRatesDocument,
        variables: {
          user_id: this.modalState.additionalData!.userId,
          ...this.form.serializeHourlyRates(),
        },
      });

      if (!response.errors?.length) {
        this.appStore.UIStore.toast({
          title: t`User was updated successfully`,
          status: "success",
        });
        this.modalState.onClose();
      }
    } catch {
      this.appStore.UIStore.toast({
        title: t`Unable to update user. Please try again later.`,
        status: "error",
      });
    }
  }

  private async onSubmitPlanningAndUtilization() {
    const formResponse = await this.form.planningAndUtilizationForm.validate();
    if (formResponse.hasError) return;

    try {
      const responseRange = await client.mutate<
        SetUserProfilePlanningRangesMutation,
        SetUserProfilePlanningRangesMutationVariables
      >({
        mutation: SetUserProfilePlanningRangesDocument,
        variables: {
          user_id: this.modalState.additionalData!.userId,
          ...this.form.serializePlanningRanges(),
        },
      });

      const responseUser = await client.mutate<
        UpdateUserMutation,
        UpdateUserMutationVariables
      >({
        mutation: UpdateUserDocument,
        variables: {
          input: {
            id: this.modalState.additionalData!.userId,
            ...this.form.serializePlanningSettings(),
          },
        },
      });

      if (!responseRange.errors?.length && !responseUser.errors?.length) {
        this.appStore.UIStore.toast({
          title: t`User was updated successfully`,
          status: "success",
        });
        this.modalState.onClose();
      }
    } catch {
      this.appStore.UIStore.toast({
        title: t`Unable to update user. Please try again later.`,
        status: "error",
      });
    }
  }

  async disable2FA() {
    const userId = this.modalState.additionalData!.userId;
    if (!userId) return;

    const { data } = await client.mutate<
      DisableTwoFactorAuthenticationMutation,
      DisableTwoFactorAuthenticationMutationVariables
    >({
      mutation: DisableTwoFactorAuthenticationDocument,
      variables: {
        id: userId,
      },
    });

    if (!data?.disableTwoFactorAuthentication) return;

    this.modalState.additionalData?.onSubmit?.();
  }

  enable2FA() {
    const currentLocation = window.location.href;

    Router.push({
      pathname: "/auth/two-factor/enable",
      query: { redirect_to: currentLocation },
    });
  }
}
