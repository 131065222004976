import {
  Box,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import {
  useAddScopedUsersToTaskMutation,
  User,
  UserScopeEnum,
} from "@src/__generated__/graphql";
import { AddButton } from "@src/components/ui-kit";
import { onError } from "@src/utils/apollo";
import { userScopeToType } from "@src/utils/userScopeToType";
import { UserSelect } from "@src/widgets/UserSelect";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

export type AddScopedUsersPopoverProps = {
  taskId: string;
  userIds: User["id"][];
  scope: UserScopeEnum;
};
export const AddScopedUsersPopover = observer(function AddScopedUsersPopover({
  scope,
  taskId,
  userIds,
}: AddScopedUsersPopoverProps) {
  const [user_ids, setUserIds] = React.useState<User["id"][]>([]);

  const [updateTask] = useAddScopedUsersToTaskMutation({
    ...onError(),
  });

  useEffect(() => {
    setUserIds(userIds);
  }, [userIds]);

  const handleUserIdsChange = (newUserIds: User["id"][]) => {
    setUserIds(newUserIds);
    updateTask({
      variables: {
        id: taskId,
        user_ids: newUserIds,
      },
    });
  };

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <Box>
          <AddButton
            variant="outline"
            rounded="full"
            colorScheme="grey"
            size="sm"
            key="client"
            iconType="normal"
          >
            {capitalize(scope)}
          </AddButton>
        </Box>
      </PopoverTrigger>

      <Portal>
        <PopoverContent>
          <PopoverBody>
            <Heading mb="3" size="xs">
              <Trans>Add</Trans>
              {" " + capitalize(scope)}
            </Heading>
            <UserSelect
              isMulti
              asPortal
              userType={userScopeToType(scope)}
              value={user_ids}
              onChange={handleUserIdsChange}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
});
