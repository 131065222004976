import { Box, Flex, HStack, StackProps, Text, Tooltip } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { User } from "@src/__generated__/graphql";
import { Avatar, UserTooltipProps } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { userScopeToType } from "@src/utils/userScopeToType";
import { capitalize } from "lodash";
import { FunctionComponent } from "react";
import { timeToHrs } from "./ModalCommunication";

type PositionBadgeWithHoursProps = {
  user?:
    | (UserTooltipProps["user"] & {
        first_name?: string | null;
        last_name?: string | null;
        scope?: User["scope"];
      })
    | null;
  workTypeTitle: string;
  reportedValue: string | number;
  plannedValue: string | number;
} & StackProps;

export const PositionBadgeWithHours: FunctionComponent<
  PositionBadgeWithHoursProps
> = ({ reportedValue, plannedValue, user, workTypeTitle, ...props }) => {
  const userType = userScopeToType(user?.scope?.value);
  return (
    <Tooltip
      label={
        !user?.scope && (
          <Box>
            <Box>{`${user?.full_name} / ${workTypeTitle}`}</Box>
            <Box>
              <Trans>Tracked:</Trans>&nbsp;
              {timeToHrs(Number(reportedValue))}
              <Trans>h</Trans>
            </Box>
            <Box>
              <Trans>Budgeted in task:</Trans>&nbsp;
              {timeToHrs(Number(plannedValue))}
              <Trans>h</Trans>
            </Box>
          </Box>
        )
      }
    >
      <HStack
        h="8"
        pr="3"
        pl="1"
        borderWidth="1px"
        borderColor="grey.100"
        rounded="full"
        spacing="2"
        {...props}
      >
        <Box>
          <Avatar
            name={user?.full_name}
            src={user?.image?.urls.thumbnail}
            size="xs"
            colorScheme={user?.profile?.hex_color}
          />
        </Box>

        {user?.first_name && user?.last_name && (
          <Text>
            {user.first_name[0]}.&nbsp;{user.last_name}
          </Text>
        )}

        {!user?.scope ? (
          <Flex>
            <HStack spacing="2px">
              <Icon w="3" h="3" name="clock" />
              <Text fontSize="xs" fontWeight="medium">
                <Trans>{timeToHrs(Number(reportedValue))}h</Trans>
              </Text>
            </HStack>
            <Text mx="2px" color="grey.500" fontSize="xs" fontWeight="medium">
              /
            </Text>
            <Text fontSize="xs" fontWeight="medium">
              <Trans>{timeToHrs(Number(plannedValue))}h</Trans>
            </Text>
          </Flex>
        ) : (
          <Text fontSize="xs" fontWeight="medium">
            {capitalize(userType)}
          </Text>
        )}
      </HStack>
    </Tooltip>
  );
};
