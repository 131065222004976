import {
  Box,
  Button,
  Checkbox,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import {
  AvatarUpload,
  ColorPicker,
  FormRow,
  LoadingOverlay,
  NumberInput,
  TextField,
  TimeInput,
} from "@src/components/ui-kit";
import { Disable } from "@src/components/ui-kit/Disable";
import { can, cannot } from "@src/utils/components/permissions";
import { formatDuration } from "@src/utils/formatters";
import {
  fieldToCheckboxProps,
  fieldToInputProps,
} from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks/useStore";
import { BrandSelect } from "@src/widgets/BrandSelect/BrandSelect";
import { PositionSelect } from "@src/widgets/PositionSelect";
import { RoleSelect } from "@src/widgets/RoleSelect/RoleSelect";
import { TaskOption, TaskSelect } from "@src/widgets/TaskSelect/TaskSelect";
import { TeamSelect } from "@src/widgets/TeamSelect";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";

const USER_PLACEHOLDER_IMAGE =
  process.env.NODE_ENV === "test" ? "" : require("@src/assets/img/user.png");

export const CreateUserModal = observer(function CreateUserModal() {
  const { createUserModalStore, workspaceStore } = useStore();

  return (
    <Modal
      isOpen={createUserModalStore.modalState.isOpen}
      onClose={createUserModalStore.modalState.onClose}
      size="2xl"
      trapFocus={false}
    >
      <ModalOverlay />

      <ModalContent>
        <LoadingOverlay
          isLoading={createUserModalStore.isLoading}
          position="absolute"
          transparent={false}
        >
          <ModalHeader>
            <Trans>Invite user</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              align={{
                base: "center",
                md: "start",
              }}
              direction={{
                base: "column",
                md: "row",
              }}
              overflowY="auto"
              spacing="4"
            >
              <Box w="25%" mt="4">
                <AvatarUpload
                  placeholderImage={USER_PLACEHOLDER_IMAGE}
                  roundedFull
                  imageSrc={
                    typeof createUserModalStore.form.avatar.value === "string"
                      ? createUserModalStore.form.avatar.value
                      : ""
                  }
                  onChange={(val) => {
                    createUserModalStore.form.avatar.onChange(val);
                  }}
                />
              </Box>

              <Box
                w={{
                  base: "full",
                  md: "75%",
                }}
              >
                <FormRow title={<Trans>First name</Trans>}>
                  <TextField
                    id="field-user-first-name"
                    {...fieldToInputProps(createUserModalStore.form.first_name)}
                    type="text"
                  />
                </FormRow>
                <FormRow title={<Trans>Last name</Trans>}>
                  <TextField
                    id="field-user-last-name"
                    {...fieldToInputProps(createUserModalStore.form.last_name)}
                    type="text"
                  />
                </FormRow>
                <FormRow title={<Trans>Codename</Trans>}>
                  <TextField
                    id="field-user-codename"
                    {...fieldToInputProps(createUserModalStore.form.codename)}
                    type="text"
                  />
                </FormRow>
                <FormRow title={<Trans>E-mail</Trans>}>
                  <TextField
                    id="field-user-email"
                    placeholder={t`example@web.sk`}
                    {...fieldToInputProps(createUserModalStore.form.email)}
                    type="email"
                  />
                </FormRow>
                {createUserModalStore.form.hasAccessToField("phone") && (
                  <FormRow title={<Trans>Phone</Trans>}>
                    <TextField
                      id="field-user-phone"
                      placeholder={t`+421 111 222 333`}
                      {...fieldToInputProps(createUserModalStore.form.phone)}
                      type="tel"
                    />
                  </FormRow>
                )}
                {createUserModalStore.form.hasAccessToField("note") && (
                  <FormRow title={<Trans>Note</Trans>}>
                    <TextField
                      id="field-user-note"
                      {...fieldToInputProps(createUserModalStore.form.note)}
                      type="text"
                    />
                  </FormRow>
                )}
                {createUserModalStore.form.hasAccessToField("team_id") && (
                  <FormRow title={<Trans>Team</Trans>}>
                    <TeamSelect
                      asPortal
                      id="field-user-team"
                      label={""}
                      {...fieldToInputProps(createUserModalStore.form.team_id)}
                    />
                  </FormRow>
                )}
                {createUserModalStore.form.hasAccessToField(
                  "default_work_type_id",
                ) && (
                  <FormRow title={<Trans>Position</Trans>}>
                    <PositionSelect
                      asPortal
                      id="field-user-position"
                      label={""}
                      {...fieldToInputProps(
                        createUserModalStore.form.default_work_type_id,
                      )}
                    />
                  </FormRow>
                )}
                {createUserModalStore.form.hasAccessToField("role_ids") &&
                  can("user_updateAllfredData") && (
                    <FormRow title={<Trans>Role</Trans>}>
                      <RoleSelect
                        asPortal
                        isMulti
                        placeholder={t`Select roles`}
                        {...fieldToInputProps(
                          createUserModalStore.form.role_ids,
                        )}
                      />
                    </FormRow>
                  )}
                {createUserModalStore.form.hasAccessToField(
                  "internal_hourly_rate",
                ) &&
                  can("user_accessAccountingAttributes") && (
                    <FormRow title={<Trans>Internal hourly rate</Trans>}>
                      <NumberInput
                        currency
                        disabled={cannot("user_updateWorkData")}
                        onChange={(val) =>
                          createUserModalStore.form.internal_hourly_rate.onChange(
                            Number(val),
                          )
                        }
                        suffix={workspaceStore.settings?.currency.code}
                        value={createUserModalStore.form.internal_hourly_rate.value.toString()}
                      />
                    </FormRow>
                  )}
                {createUserModalStore.form.hasAccessToField("plannable") &&
                  can("user_updatePlanningData") && (
                    <Fragment>
                      <Divider mb="4" />

                      <FormRow title={<Trans>Plannable</Trans>}>
                        <Checkbox
                          {...fieldToCheckboxProps(
                            createUserModalStore.form.plannable,
                          )}
                        >
                          <Trans>Show user in planning</Trans>
                        </Checkbox>
                        <Checkbox
                          {...fieldToCheckboxProps(
                            createUserModalStore.form.show_in_utilization,
                          )}
                        >
                          <Trans>Show in utilization report</Trans>
                        </Checkbox>
                      </FormRow>
                    </Fragment>
                  )}
                {createUserModalStore.form.hasAccessToField(
                  "plannable_capacity",
                ) &&
                  can("user_updatePlanningData") && (
                    <Disable
                      isDisabled={!createUserModalStore.form.plannable.value}
                    >
                      <FormRow title={<Trans>Daily capacity</Trans>}>
                        <Checkbox
                          mb="1"
                          isChecked={
                            createUserModalStore.form.from_default_capacity
                              .value
                          }
                          onChange={
                            createUserModalStore.handleFromDefaultCapacityChange
                          }
                        >
                          <Trans>
                            Use workspace default (
                            {formatDuration(
                              workspaceStore.settings
                                ?.default_user_plannable_capacity ?? 0,
                            )}
                            )
                          </Trans>
                        </Checkbox>
                        <TimeInput
                          isDisabled={
                            cannot("user_updatePlanningData") ||
                            createUserModalStore.form.from_default_capacity
                              .value
                          }
                          value={
                            createUserModalStore.form.plannable_capacity.value
                          }
                          onChange={(val) =>
                            createUserModalStore.form.plannable_capacity.onChange(
                              val ?? 0,
                            )
                          }
                        />
                      </FormRow>
                    </Disable>
                  )}
                {createUserModalStore.form.hasAccessToField("hex_color") && (
                  <FormRow title={<Trans>Color</Trans>}>
                    <ColorPicker
                      isDisabled={cannot("user_updatePlanningData")}
                      color={createUserModalStore.form.hex_color.$}
                      onChange={createUserModalStore.form.hex_color.onChange}
                    />
                  </FormRow>
                )}
                {createUserModalStore.form.hasAccessToField("brand_ids") &&
                  can("user_updateAllfredData") && (
                    <FormRow title={<Trans>Access to brands</Trans>}>
                      <BrandSelect
                        asPortal
                        isMulti
                        id="field-user-brands"
                        {...fieldToInputProps(
                          createUserModalStore.form.brand_ids,
                        )}
                      />
                    </FormRow>
                  )}
                {createUserModalStore.form.hasAccessToField("task_ids") &&
                  can("user_updateAllfredData") && (
                    <FormRow title={<Trans>Access to tasks</Trans>}>
                      <TaskSelect
                        asPortal
                        isMulti
                        disableDoneTasks
                        withCreateTask={false}
                        value={createUserModalStore.form.task_ids.value}
                        onChange={(taskId, options) => {
                          createUserModalStore.form.task_ids.onChange(
                            taskId as string[],
                          );
                          createUserModalStore.form.selectedTasks = [
                            ...(createUserModalStore.form.selectedTasks ?? []),
                            ...(options as TaskOption[]),
                          ];
                        }}
                        defaultOptions={
                          !!createUserModalStore.form.selectedTasks?.length
                            ? createUserModalStore.form.selectedTasks
                            : undefined
                        }
                        options={[]}
                        id="field-user-tasks"
                      />
                    </FormRow>
                  )}
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="violet"
              isLoading={createUserModalStore.isSubmitting}
              onClick={() => createUserModalStore.submit()}
            >
              <Trans>Invite</Trans>
            </Button>
          </ModalFooter>
        </LoadingOverlay>
      </ModalContent>
    </Modal>
  );
});
