import {
  AvatarGroup,
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Tag,
  TagProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import {
  UserScopeEnum,
  useUpdateTaskScopesMutation,
} from "@src/__generated__/graphql";
import { TaskType } from "@src/components/modules/resource-planning/tasks/listing/TasksListingStore";
import { Icon } from "@src/components/ui-kit/Icon";
import { timeToHrs } from "@src/components/widgets/Modals/ModalCommunication/ModalCommunication";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { formatDeadline } from "@src/utils/formatDeadline";
import { IconNames } from "@src/utils/types/iconNames";
import {
  differenceInDays,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from "date-fns";
import { compact, uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import { now } from "mobx-utils";
import React, { FunctionComponent, useState } from "react";
import { Avatar } from "../Avatar";
import { Link } from "../Link";
import { UncollapseOnHover } from "../UncollapseOnHover";
import { UserTooltip } from "../UserTooltip";
import { DatePicker } from "../datePicker";

type TDeadlineProps = {
  deadline: TaskType["deadline"];
  dateForColorScheme?: Date | null;
  size?: TagProps["size"];
  fontSize?: TagProps["fontSize"];
  onChange?: (newDeadline: Date | null) => void;
  isLoading?: boolean;
};

type TaskOurWorkBudgetItem = NonNullable<TaskType["ourWorkBudgetItem"]>;

type TPlannedBudgetedTimeProps = {
  plannedTime: NonNullable<TaskType["stats"]>["planned_time"] | null;
  spentTime: NonNullable<TaskType["stats"]>["spent_time"] | null;
  projectId: TaskOurWorkBudgetItem["project"]["id"] | undefined;
  budgetItemId: TaskOurWorkBudgetItem["id"] | undefined;
  usesBudgetovac:
    | TaskOurWorkBudgetItem["project"]["uses_budgetovac"]
    | undefined;
} & TClientAccessProps;

type TAvatarsProps = {
  positions: TaskType["positions"];
};

type TClientAccessProps = {
  taskId: TaskType["id"];
  scopes: TaskType["scopes"];
};

export function resetTime(date: Date): Date {
  return setMilliseconds(setSeconds(setMinutes(setHours(date, 0), 0), 0), 0);
}

function getDeadlineColor(deadline: Date, customDate?: Date | null) {
  const dateToCompare = customDate ?? new Date(now());
  const resetDeadline = resetTime(deadline);
  const resetDateToCompare = resetTime(dateToCompare);
  const difference = differenceInDays(resetDeadline, resetDateToCompare);

  if (difference < 0) return "red";
  if (difference >= 0 && difference <= 2) return "green";

  return "grey";
}

export const TaskDeadline = observer(function TaskDeadline({
  deadline,
  dateForColorScheme = undefined,
  size = "sm",
  fontSize,
  onChange,
  isLoading,
}: TDeadlineProps) {
  if (!deadline) return <span />;
  const isEditable = !!onChange;

  return (
    <WrapComponent
      if={isEditable}
      with={(children) => (
        <DatePicker
          trigger={children}
          usePortal
          isClearable
          clearButtonLabel={t`Clear deadline`}
          selected={deadline}
          onChange={(range) => {
            onChange?.(range?.start ?? null);
          }}
        />
      )}
    >
      <Flex>
        <Tag
          fontSize={fontSize}
          whiteSpace="nowrap"
          cursor={isEditable ? "pointer" : undefined}
          colorScheme={getDeadlineColor(deadline, dateForColorScheme)}
          size={size}
        >
          {isLoading ? (
            <Spinner size="xs" />
          ) : (
            <WrapComponent
              if={isEditable}
              with={(children) => (
                <UncollapseOnHover
                  dataComponentName="deadline-edit-icon"
                  color="inherit"
                  width="3"
                  hiddenComponent={
                    <Icon name="edit-02" h="3" w="3" color="inherit" ml="1" />
                  }
                >
                  <Text>{children}</Text>
                </UncollapseOnHover>
              )}
            >
              {formatDeadline(new Date(deadline))}
            </WrapComponent>
          )}
        </Tag>
      </Flex>
    </WrapComponent>
  );
});

export const TaskPlannedBudgetedTime: FunctionComponent<TPlannedBudgetedTimeProps> =
  observer(function TaskPlannedBudgetedTime({
    taskId,
    scopes,
    plannedTime,
    spentTime,
    projectId,
    budgetItemId,
    usesBudgetovac,
  }) {
    return (
      <HStack justify="space-between">
        <HStack spacing="1.5">
          <Flex
            align="center"
            justify="start"
            gap="1"
            minW="max-content"
            textAlign="end"
            onClick={(e) => e.stopPropagation()}
          >
            {spentTime !== null ? (
              <React.Fragment>
                <Icon
                  name="clock"
                  color={spentTime === 0 ? "gray.500" : "jade.700"}
                />
                <Link
                  href={{
                    pathname: "/projects/time-report",
                    query: { id: projectId, budgetItemId: budgetItemId },
                  }}
                  color={spentTime === 0 ? "gray.500" : "teal.700"}
                >
                  <Trans>{timeToHrs(spentTime, 0)}h</Trans>
                </Link>
              </React.Fragment>
            ) : (
              <Spinner size="sm" />
            )}
          </Flex>
          {/* TODO: Finish after API will be done */}
          {/* <Divider h="3" borderColor="gray.400" orientation="vertical" />
        <Box minW="max-content" textAlign="start" onClick={(e) => e.stopPropagation()}>
        <Link
        href={{
          pathname: '/',
          query: { id: '' },
          }}
          color={0 === 0 ? 'gray.500' : 'teal.700'}
          >
          {timeToHrs(0, 0)}h
          </Link>
          </Box> */}
          <Divider h="3" borderColor="gray.400" orientation="vertical" />
          <Box
            minW="max-content"
            textAlign="start"
            onClick={(e) => e.stopPropagation()}
          >
            {plannedTime !== null ? (
              <Link
                href={{
                  pathname: usesBudgetovac
                    ? "/projects/budgets"
                    : "/projects/budget",
                  query: { id: projectId },
                }}
                color="initial"
              >
                <Trans>{timeToHrs(plannedTime, 0)}h</Trans>
              </Link>
            ) : (
              <Spinner size="sm" />
            )}
          </Box>
        </HStack>
        <TaskAccess taskId={taskId} scopes={scopes} />
      </HStack>
    );
  });

export const TaskAvatars: FunctionComponent<TAvatarsProps> = ({
  positions,
}) => {
  const uniqUsers = compact(
    uniqBy(
      positions.map((p) => p.user),
      (user) => user?.id,
    ),
  );

  return (
    <AvatarGroup
      sx={{
        ".chakra-avatar__excess": {
          borderWidth: "1.5px",
          borderColor: "white",
        },
      }}
      minW="100px"
      max={3}
      spacing="-3"
    >
      {uniqUsers.map((u) => (
        <UserTooltip key={u.id} user={u}>
          <Avatar
            colorScheme={u.profile?.hex_color}
            name={u.full_name}
            src={u.image?.urls.thumbnail ?? undefined}
            borderWidth="1.5px"
            borderColor="white"
          />
        </UserTooltip>
      ))}
    </AvatarGroup>
  );
};

export const TaskAccess: FunctionComponent<TClientAccessProps> = ({
  taskId,
  scopes,
}) => {
  const [accessState, setAccessState] = useState<"enabled" | "disabled">(
    scopes.length ? "enabled" : "disabled",
  );

  const [update, { loading }] = useUpdateTaskScopesMutation();

  const actions: Record<
    typeof accessState,
    {
      tooltip: string;
      icon: IconNames;
      onClick: () => void;
    }
  > = {
    enabled: {
      tooltip: t`Task is accessible for clients and partners with access to this task. Toggle to set private.`,
      icon: "users-group",
      onClick: async () => {
        const res = await update({
          variables: {
            input: {
              id: taskId,
              scopes: [],
            },
          },
        });

        if (res.data?.updateTask.id) {
          setAccessState("disabled");
        }
      },
    },
    disabled: {
      tooltip: t`Task is private and only accessible to internal agency users. Toggle to make accessible for clients and partners.`,
      icon: "users-group-locked",
      onClick: async () => {
        const res = await update({
          variables: {
            input: {
              id: taskId,
              scopes: [UserScopeEnum.Client, UserScopeEnum.Partner],
            },
          },
        });

        if (res.data?.updateTask.id) {
          setAccessState("enabled");
        }
      },
    },
  };

  const currentAction = actions[accessState];

  return (
    <Tooltip label={currentAction.tooltip}>
      <IconButton
        color="grey.500"
        opacity={0}
        _hover={{ color: "grey.900" }}
        _groupHover={{ opacity: 1 }}
        transition="opacity 0.2s"
        aria-label={currentAction.tooltip}
        colorScheme="gray"
        icon={<Icon name={currentAction.icon} w="5" h="5" />}
        isLoading={loading}
        onClick={(e) => {
          e.stopPropagation();
          currentAction.onClick();
        }}
        variant="plain"
      />
    </Tooltip>
  );
};
