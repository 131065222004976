import { Box, Heading, HStack, Stack, TabPanel } from "@chakra-ui/react";
import { Spinner } from "@components/ui-kit";
import { Trans } from "@lingui/macro";
import { useLastSeenCommentMutation } from "@src/__generated__/graphql";
import { AttachmentView } from "@src/components/widgets/Modals/ModalCommunication/components/Attachments/TaskAttachment";
import Comment from "@src/components/widgets/Modals/ModalCommunication/components/comment/Comment";
import { CommentAppearance } from "@src/components/widgets/Modals/ModalCommunication/components/comment/CommentAppearance";
import { SliderArrow } from "@src/components/widgets/Modals/ModalCommunication/components/tabs/SliderArrow";
import useComments from "@src/components/widgets/Modals/ModalCommunication/useComments";
import { UserType } from "@src/stores/models/Me";
import { onError } from "@src/utils/apollo";
import { useStore } from "@src/utils/hooks";
import useIntersectionObserver from "@src/utils/hooks/useIntersectionObserver";
import { userTypeToScope } from "@src/utils/userTypeToScope";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";

export type CommunicationTabProps = {
  userType: UserType;
};

const USER_TYPE_TO_COLOR_SCHEME_MAP = {
  client: "pink",
  internal: "grey",
  partner: "blue",
} as const;

export const CommunicationTab = observer(function CommunicationTab({
  userType,
}: CommunicationTabProps) {
  const { taskDetailModalStore: store } = useStore();

  const [lastSeenComment] = useLastSeenCommentMutation({ ...onError() });

  const [
    comments,
    {
      loadingComments,
      hasMoreComments,
      fetchMoreComments,
      replaceComment,
      deleteComment,
    },
  ] = useComments(
    { taskId: store.taskId.value, scope: userTypeToScope(userType) },
    store.drawerState.isOpen,
    store,
  );

  const loadMore = useCallback(() => {
    if (hasMoreComments && !loadingComments) {
      fetchMoreComments();
    }
  }, [hasMoreComments, loadingComments]);

  useIntersectionObserver({
    threshold: 0.25,
    target: store.commentsLoaderRef,
    onIntersect: loadMore,
  });

  useIntersectionObserver({
    threshold: 0.7,
    target: store.firstCommentRef,
    onIntersect: () => {
      if (comments && !!comments.length && !store.seenLastComment.value) {
        store.seenLastComment.on();
        lastSeenComment({
          variables: {
            comment_id: comments[0].id,
          },
        });
      }
    },
  });

  const attachmentsRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const checkScrollability = useCallback(() => {
    if (attachmentsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = attachmentsRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollWidth > clientWidth);
    }
  }, []);

  useEffect(() => {
    checkScrollability();
    window.addEventListener("resize", checkScrollability);
    return () => window.removeEventListener("resize", checkScrollability);
  }, [checkScrollability, store.task.value?.commentFiles]);

  const scrollAttachments = useCallback(
    (direction: "left" | "right") => {
      if (attachmentsRef.current) {
        const container = attachmentsRef.current;
        const scrollAmount = container.clientWidth;
        const maxScroll = container.scrollWidth - container.clientWidth;

        let newScrollPosition =
          container.scrollLeft +
          (direction === "left" ? -scrollAmount : scrollAmount);
        newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScroll));

        container.scrollTo({
          left: newScrollPosition,
          behavior: "smooth",
        });

        // Update scroll buttons visibility after scrolling
        setTimeout(checkScrollability, 100);
      }
    },
    [checkScrollability],
  );

  return (
    <TabPanel px="0">
      <Stack mb="4">
        <Heading mb="2" px="40px" size="sm">
          <Trans>Attachments</Trans>
        </Heading>
        <Box pos="relative">
          <SliderArrow
            visibility={canScrollLeft ? "visible" : "hidden"}
            direction="left"
            onClick={() => scrollAttachments("left")}
          />
          <HStack
            ref={attachmentsRef}
            overflowX="auto"
            px="40px"
            onScroll={checkScrollability}
            style={{ scrollbarWidth: "none" }}
          >
            {store.task.value?.commentFiles.map((file) => (
              <AttachmentView
                key={file.public_id}
                mimeType={file.mime_type}
                w="16%"
                minW="16%"
                height="120"
                id={file.public_id}
                name={file.filename}
                urls={file.urls}
                removeDisabled
              />
            ))}
          </HStack>
          <SliderArrow
            direction="right"
            visibility={canScrollRight ? "visible" : "hidden"}
            onClick={() => scrollAttachments("right")}
          />
        </Box>
      </Stack>

      <Box ref={store.commentsContainerRef}>
        {comments.map((comment, index) => {
          if (index === 0) {
            return (
              <CommentAppearance key={comment.id} commentId={comment.id}>
                <Comment
                  ref={store.firstCommentRef}
                  comment={comment}
                  onUpdated={replaceComment}
                  onDeleted={deleteComment}
                  taskId={store.task.value?.id}
                  colorScheme={USER_TYPE_TO_COLOR_SCHEME_MAP[userType]}
                />
              </CommentAppearance>
            );
          } else {
            return (
              <Comment
                key={comment.id}
                comment={comment}
                onUpdated={replaceComment}
                onDeleted={deleteComment}
                taskId={store.task.value?.id}
                colorScheme={USER_TYPE_TO_COLOR_SCHEME_MAP[userType]}
              />
            );
          }
        })}
      </Box>
      <Box ref={store.commentsLoaderRef} h="10">
        {loadingComments && <Spinner />}
      </Box>
    </TabPanel>
  );
});
